<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const k4_Url = runtimeConfig.public.karriere.url;
</script>

<template>
    <p>
        *Diese Funktion wird von unserem Partner excentos unterstützt.
        <JamBaseLink
            as="a"
            :href="`${k4_Url}/remarketing-und-cookies#viii-produktberater-by-excentos`"
            target="_blank"
            variant="dark-gray"
            class="text-xs"
            has-trailing-icon
            icon-name="extern-link"
            icon-size="small"
        >
            Weitere Informationen
        </JamBaseLink>
    </p>
</template>
